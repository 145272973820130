@font-face {
  font-family: "LineaturaLight";
  src: url("./fonts/Lineatura-Light.woff") format("woff"),
    url("./fonts/Lineatura-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lineatura";
  src: url("./fonts/Lineatura.woff") format("woff"),
    url("./fonts/Lineatura.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html {
  background: black;
}

#myVideo {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
}

.fade-in {
  transition: opacity 1s ease;
}
.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

.okbutton {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
